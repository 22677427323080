<template>
  <v-dialog v-model="dialog" width="800" retain-focus @click:outside="cancel" @keydown.esc="cancel">
    <v-card flat class="pa-6">
      <h1 align="center">Atenção! As primeiras 50 builds ativas serão abortadas. Tem certeza que deseja continuar?</h1>
      <div class="mt-6 px-4">
        <span>Insira abaixo o motivo da interrupção da(s) build(s):</span>
        <v-text-field v-model="abort_params.abort_reason" outlined label="Motivo" />
      </div>
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <v-card-actions v-else class="mt-6">
        <v-btn :disabled="loading || !abort_params.abort_reason" block color="error" @click="massiveAbort"
          ><v-icon class="mr-2">mdi-alert</v-icon> Continuar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_MASSIVE_ABORT } from '@/modules/app/graphql/index.js'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    loading: false,
    abort_params: {
      abort_reason: '',
      abort_with_success: false,
      skip_notifications: false
    }
  }),
  methods: {
    cancel() {
      this.cleanFields()
      this.$emit('update:dialog', false)
      this.$emit('close')
    },
    cleanFields() {
      this.abort_params.abort_reason = ''
    },
    async massiveAbort() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_MASSIVE_ABORT,
          variables: {
            abort_params: this.abort_params
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_build']
          }
        })
        this.$snackbar({ message: 'Abort de builds realizado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao realizar abort massivo de builds', snackbarColor: '#F44336' })
      }

      this.loading = false
      this.cancel()
    }
  }
}
</script>
