import gql from 'graphql-tag'

export const QUERY_GET_APP_BUILD_LOGS = gql`
  query($fast_lane_action: FastlaneCommands, $page: Int, $pageSize: Int, $search: String, $sort: [String!], $sortDirection: [SortDirection!]) {
    appBuildLogs(
      filters: { fast_lane_action: $fast_lane_action }
      pagination: { page: $page, pageSize: $pageSize, search: $search, sort: $sort, sortDirection: $sortDirection }
    ) {
      data {
        _id
        app_build
        branch
        account_id
        build_status
        build_output {
          build_number
          build_slug
          build_url
          message
          service
          slug
          status
          triggered_workflow
        }
        changelog
        created_at
        fast_lane_action
        test
        user {
          _id
          name
          login
        }
        workflow_id
        details {
          triggered_at
          started_on_worker_at
          environment_prepare_finished_at
          finished_at
          slug
          status
          status_text
          abort_reason
          is_on_hold
          is_processed
          is_status_sent
          branch
          build_number
          commit_hash
          commit_message
          tag
          triggered_workflow
          triggered_by
          machine_type_id
          stack_identifier
          original_build_params {
            workflow_id
            branch
            environments {
              value
              is_expand
              key
            }
          }
          pipeline_workflow_id
          pull_request_id
          pull_request_target_branch
          pull_request_view_url
          commit_view_url
          credit_cost
        }
      }
      count
    }
  }
`
export const QUERY_GET_AVAILABLE_APPS = gql`
  query($pagination: PaginationParams!, $filters: PaginationAppFilters) {
    apps(pagination: $pagination, filters: $filters) {
      data {
        _id
        db_name
        name
        channels {
          app {
            active
            firebase {
              certificate_version
            }
            points {
              active
            }
            push {
              credentials {
                firebase_version
              }
              provider
            }
            build {
              account_id
              app_type
              android {
                type_release
              }
              app_name
              apple {
                release_mode
                type_submit_review_ios
              }
              assets {
                banner_store_android
                icon_android
                icon_ios
                icon_navbar
                icon_store_android
                splash
              }
              codepush {
                android
                api_token
                deployment_key_ios
                deployment_key_android
                force_install
                ios
              }
              colors {
                main
                main_text
                nav
                nav_text
                secondary
                secondary_text
                splash
              }
              debug_api
              facebook {
                app_id
                bundle
                client_token
                display_name
              }
              google {
                cloud_api_key
              }
              keystore {
                key_alias
                key_password
                store_file
                store_password
              }
              metadata {
                android {
                  aab_link
                  google_services_json {
                    client {
                      api_key {
                        current_key
                      }
                      client_info {
                        android_client_info {
                          package_name
                        }
                        mobilesdk_app_id
                      }
                      oauth_client {
                        android_info {
                          certificate_hash
                          package_name
                        }
                        client_id
                        client_type
                      }
                      services {
                        appinvite_service {
                          other_platform_oauth_client {
                            client_id
                            client_type
                            ios_info {
                              bundle_id
                            }
                          }
                        }
                      }
                    }
                    configuration_version
                    project_info {
                      project_id
                      project_number
                      storage_bucket
                    }
                  }
                }
                ios {
                  google_service_info
                }
              }
              store {
                android_bundle_id
                android_build_code
                android_version_code
                ios_bundle_id
                ios_build_code
                ios_version_code
                name
              }
              web_client_id
            }
          }
        }
      }
      count
    }
  }
`
export const MUTATION_ABORT_BUILD = gql`
  mutation($build_slug: String!, $abort_reason: String!, $abort_with_success: Boolean!, $skip_notifications: Boolean!) {
    abortBuild(
      build_slug: $build_slug
      abort_params: { abort_reason: $abort_reason, abort_with_success: $abort_with_success, skip_notifications: $skip_notifications }
    ) {
      message
      success
      output {
        status
      }
    }
  }
`
export const MUTATION_RETRY_BUILD = gql`
  mutation($build_id: String!) {
    retryBuild(build_id: $build_id) {
      message
      success
      output {
        build_number
        build_slug
        build_url
        message
        service
        slug
        status
        triggered_workflow
      }
    }
  }
`
export const MUTATION_START_BUILD = gql`
  mutation($build_params: BuildParamsInput!) {
    startBuild(build_params: $build_params) {
      success
      message
      output {
        build_url
        build_slug
        build_number
        status
        message
        service
        slug
        triggered_workflow
      }
    }
  }
`
export const MUTATION_MASSIVE_BUILD = gql`
  mutation($build_params: BuildParamsInput!, $apps: AppList!) {
    massiveBuild(build_params: $build_params, apps: $apps) {
      success
      message
      output {
        status
        build_url
        build_slug
      }
    }
  }
`
export const MUTATION_MASSIVE_ABORT = gql`
  mutation($abort_params: AbortBuildInput!) {
    abortRunningServices(abort_params: $abort_params) {
      success
      message
      output {
        status
      }
    }
  }
`
export const QUERY_GET_MASSIVE_BUILD_LIST = gql`
  query {
    massiveBuildList {
      _id
      name
      channels {
        app {
          build {
            app_name
          }
        }
      }
    }
  }
`
